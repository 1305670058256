/* eslint-disable */
import { ActionTypes } from "../actions/adminActions";

export const initialData = {
    is_login: "no",
    user_id: "",
    device_id: "",
    selfInfo: {},
    accessToken: "",
    ModalPopup: {
        title: "",
        name: "",
        description: "",
        data: {},
        show: "",
        callBackModal: () => null,
        callBackCancelModal: () => null,
        buttonSuccess: "",
        buttonCancel: ""
    },
    permissionList: [],
    dashboardCount: {},
    userList: [],
    userListIdWise: {},
    brandList: {
        list: [],
        pagination: {}
    },
    modelList: {
        list: [],
        pagination: {}
    },
    brandmodelsList: {
        list: [],
        pagination: {}
    },
    supplierList: {
        list: [],
        pagination: {}
    },
    supplierListall: [],
    supplierdetails: {},
    productList: {
        list: [],
        pagination: {}
    },
    productListAll: [],
    brandlistall: [],
    modellistall: [],
    selectedBrandWiseModel: [],
    viewProduct: {},
    addToCartLists: [],
    loaderMain: false,
    ClearCaches_setww: "",
    categoryList: {
        list: [],
        pagination: {}
    },
    categoryAllList:[],
    ClearFormSet:{
        url:"",
        action:false
    },
    settinglists: {
        list: [],
        pagination: {}
    },
    settingsObjectIdWise: {},
    SelectCurrentBrand: {},
    SelectCurrentBrandModel: {},
    SelectCurrentCategory: {},
    SelectCurrentSupplier: {},
    SelectedCurrentBrandID: "",
    packageList:[],
    jetList:[],
    boatOwnerList: {
        data: [],
        pagination: {}
    },
    boatTypeList:[],
    currentPackage:[],
    currentJet:[],
    currentImage:[],
    currentEditPackage:[],
    websocketReqRes: {
        request: null,
        msg: "",
        status: "",
        url: "",
        response:null
    },
    brokerList: {
        data: [],
        pagination: {}
    },
    customerList: {
        data: [],
        pagination: {}
    },
    boatList:{
        data:[],
        pagination:[],
    },
    faqList: {
        data: [],
        pagination: {}
    },
    contactList: {
        data: [],
        pagination: {}
    },
    termsList:[],
    policyList:[],
    boatDetails:null,
    orderListParticular:null,
    orderList: {
        data: [],
        pagination: {}
    },
    singleOrder:[],
    ownerPaymentList: {
        data: [],
        pagination: {}
    },
    brokerPaymentList: {
        data: [],
        pagination: {}
    },
    recentOrderList: {
        data: [],
        pagination: {}
    },
    testimonialList:[],
    adminDashboard:{},
    ownerDashboard:{},
    brokerDashboard:{},
    firebaseData:"",
};

const jsonParse = (data) => {
    return data?.replaceAll("None", 'null').replaceAll("False", false).replaceAll("True", true)?.replaceAll(/'/g, '"')
}

export const adminReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_STORE_FIREBASE_DATA:
      return {
          ...state,
          firebaseData: action.payload,
      };
        case ActionTypes.IS_LOGIN:
            return {
                ...state,
                is_login: action.payload,
            };
        case ActionTypes.GET_USER_ID:
            return {
                ...state,
                user_id: action.payload,
            };
        case ActionTypes.GET_DEVICE_ID:
            return {
                ...state,
                device_id: action.payload,
            };
        case ActionTypes.SELF_DETAILS:
            return {
                ...state,
                selfInfo: action.payload,
            };
        case ActionTypes.CLEAR_FORM_SET_FUCT:
            return {
                ...state,
                ClearFormSet:{
                    url:action?.payload?.url,
                    action:action?.payload?.action
                }
            };
        case ActionTypes.EDIT_SELF_DETAILS:
            return {
                ...state,
                selfInfo: action.payload,
            };
        case ActionTypes.GET_ACCESSTOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };
        case ActionTypes.LOADER_MAIN_START:
            return {
                ...state,
                loaderMain: action.payload,
            };
        case ActionTypes.GET_USER_LIST:
            return {
                ...state,
                userList: action.payload,
            };
        case ActionTypes.GET_DASHBOARD_DETAILS:
            return {
                ...state,
                dashboardCount: action.payload,
            }
        case ActionTypes.GET_USER_LIST_ID_WISE: 
            const ObjectUserListId = {}
            action.payload.map((elm) => {
                ObjectUserListId[elm?.id] = elm
            });

            return {
                ...state,
                userListIdWise: ObjectUserListId,
            }
        case ActionTypes.ADD_USER_LIST:
            const OldUserlist = [...state.userList];
            OldUserlist.unshift(action.payload);
            return {
                ...state,
                userList: OldUserlist,
            }
        case ActionTypes.EDIT_USER_LIST:
            const OldUserlistx = [...state.userList];
            const UpdateUserList = OldUserlistx.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        first_name : action.payload.first_name,
                        last_name : action.payload.last_name,
                        email : action.payload.email,
                        password : action.payload.password,
                        phone_code: action.payload.phone_code,
                        phone: action.payload.phone,
                        address : action.payload.address,
                        permissions : action.payload.permissions,
                        is_admin: action.payload.is_admin,
                        is_staff: action.payload.is_staff,
                        is_active : action.payload.is_active,
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                userList: UpdateUserList,
            }
        case ActionTypes.DELETE_USER: 
            const OldUserListz = [...state.userList];
            const NewUpdatedUserList = [];
            OldUserListz && OldUserListz.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedUserList.push(elm);
                }
            }); 
            return {
                ...state,
                userList: NewUpdatedUserList,
            };
        case ActionTypes.GET_BRAND_LIST:
            return {
                ...state,
                brandList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action?.payload?.data?.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action?.payload?.data?.pagination
                },
            };
            case ActionTypes.GET_ALL_PRODUCT_LIST:
                return {
                    ...state,
                    productListAll: (action.payload && action.payload.data && action.payload.data.data && Object.keys(action.payload.data.data).length > 0) ? action.payload.data.data : [], 
                }            
        case ActionTypes.GET_ALL_BRANDS_LIST:
            return {
                ...state,
                brandlistall: Object.keys(action.payload).length === 0 ? [] : action?.payload?.data?.data,
            };
        case ActionTypes.GET_ALL_MODEL_LIST:
            return {
                ...state,
                modellistall: Object.keys(action.payload).length === 0 ? [] : action.payload?.data?.data,
            };
        case ActionTypes.SELECT_BRAND_GET_MODEL_LIST:
            return {
                ...state,
                selectedBrandWiseModel: Object.keys(action.payload).length === 0 ? [] : action.payload.data.data,
            };
        case ActionTypes.ADD_NEW_BRAND:
            const OldBrandlist = state.brandList.list === undefined ? [] : [...state.brandList.list];
            const OldAllBrandList = state.brandlistall === undefined ? [] : [...state.brandlistall];
            OldBrandlist.unshift(action.payload.data);
            OldAllBrandList.unshift(action.payload.data);
            return {
                ...state,
                brandList: {
                    list: OldBrandlist,
                },
                brandlistall: OldAllBrandList
            };
        case ActionTypes.SELECT_CURRENT_ADDED_BRAND:
            return {
                ...state,
                SelectCurrentBrand: action.payload,
            };
        case ActionTypes.SELECT_CURRENT_ADDED_BRAND_MODEL:
            return {
                ...state,
                SelectCurrentBrandModel: action.payload,
            };
        case ActionTypes.SELECT_CURRENT_ADDED_CATEGORY:
            return {
                ...state,
                SelectCurrentCategory: action.payload,
            };
        case ActionTypes.SELECT_CURRENT_ADDED_SUPPLIER:
            return {
                ...state,
                SelectCurrentSupplier: action.payload,
            };
        case ActionTypes.GET_SELECTED_CURRENT_BRAND_IDS:
            return {
                ...state,
                SelectedCurrentBrandID: action.payload,
            };
        case ActionTypes.EDIT_BRAND:
            const OldBrandlistx = [...state.brandList.list];
            const UpdateBrandList = OldBrandlistx.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        name : action.payload.name,
                        is_active : action.payload.is_active,
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                brandList: {
                    list: UpdateBrandList,
                },
            };
        case ActionTypes.DELETE_BRAND: 
            const OldBrandListz = [...state.brandList.list];
            const NewUpdatedBrandList = [];
            OldBrandListz && OldBrandListz?.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedBrandList.push(elm);
                }
            }); 
            return {
                ...state,
                brandList: {
                    list: NewUpdatedBrandList,
                },
            };
        case ActionTypes.GET_BRAND_MODELS_LIST:
            return {
                ...state,
                brandmodelsList: {
                    list: action.payload.data.data === undefined ? [] : action.payload.data.data,
                    pagination: action.payload.pagination === undefined ? [] : action.payload.pagination
                },
            };
        case ActionTypes.GET_MODEL_LIST:
            return {
                ...state,
                modelList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.data.pagination
                },
            };
        case ActionTypes.ADD_NEW_MODEL:
            const OldModellist = state.modelList.list === undefined ? [] : [...state.modelList.list];
            OldModellist.unshift(action.payload.data);
            const OldSelectBrandWiseModel = state.selectedBrandWiseModel == undefined ? [] : [...state.selectedBrandWiseModel];
            OldSelectBrandWiseModel.unshift(action.payload.data);
            return {
                ...state,
                modelList:{
                    list: OldModellist,
                },
                selectedBrandWiseModel: OldSelectBrandWiseModel
            };
        case ActionTypes.EDIT_MODEL:
            const OldModellistx = [...state.modelList.list];
            const UpdateModelList = OldModellistx.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        name : action.payload.name,
                        brand_id : action.payload.brand_id,
                        is_active : action.payload.is_active,
                        added_by: action.payload.added_by
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                modelList: {
                    list: UpdateModelList,
                },
            };
        case ActionTypes.DELETE_MODEL: 
            const OldModelListz = [...state.modelList.list];
            const NewUpdatedModelList = [];
            OldModelListz && OldModelListz?.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedModelList.push(elm);
                }
            }); 
            return {
                ...state,
                modelList: {
                    list: NewUpdatedModelList,
                },
            };
        case ActionTypes.GET_PERMISSION_LIST:
            return {
                ...state,
                permissionList: action.payload,
            }
        case ActionTypes.GET_ALL_SUPPLIERS_LIST:
            return {
                ...state,
                supplierListall: [],
            };
        case ActionTypes.GET_SUPPLIER_LIST:
            return {
                ...state,
                supplierList: {
                    list: action.payload?.data?.data === undefined ? [] : action.payload?.data?.data,
                    pagination: action.payload.data?.pagination === undefined ? [] : action.payload.data?.pagination
                },
            };
        case ActionTypes.ADD_SUPPLIER_LIST:
            const OldSupplierlist = [...state.supplierList.list];
            OldSupplierlist.unshift(action.payload.data);
            const OldSupplierlistAll = state.supplierListall === undefined ? [] : [...state.supplierListall];
            OldSupplierlistAll.unshift(action.payload.data);
            return {
                ...state,
                supplierList:{
                    list: OldSupplierlist,
                },
                supplierListall:OldSupplierlistAll
            };
        case ActionTypes.UPDATE_SUPPLIER_LIST:
            const OldSupplierlistx = [...state.supplierList.list];
            const UpdateSupplierList = OldSupplierlistx.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        title : action.payload.title, 
                        website : action.payload.website,
                        is_active : action.payload.is_active,
                        name : action.payload.name,
                        email: action.payload.email,
                        phone_code : action.payload.phone_code,
                        phone : action.payload.phone,
                        company : action.payload.company,
                        address : action.payload.address, 
                        vat_registration_no: action.payload.vat_registration_no, 
                        registration_no: action.payload.registration_no,
                        contacts: action.payload.contacts
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                supplierList:{
                    list: UpdateSupplierList,
                },
            };
        case ActionTypes.DELETE_SUPPLIER:
            const OldSupplierListz = [...state.supplierList.list];
            const NewUpdatedSupplierList = [];
            OldSupplierListz && OldSupplierListz?.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedSupplierList.push(elm);
                }
            }); 
            return {
                ...state,
                supplierList: {
                    list: NewUpdatedSupplierList,
                },
            };
        case ActionTypes.GET_SUPPLIER_DETAILS:
            return {
                ...state,
                supplierdetails: action.payload,
            }
        case ActionTypes.GET_PRODUCT_LIST:
            return {
                ...state,
                productList: {
                    list: action?.payload?.data?.data === undefined ? [] : action?.payload?.data?.data,
                    pagination: action.payload.data?.pagination === undefined ? [] : action.payload.data?.pagination
                },
            };
        case ActionTypes.ADD_PRODUCT_LIST:
            const OldProductlist = [...state.supplierList.list];
            OldProductlist.unshift(action.payload);
            return {
                ...state,
                productList:{
                    list: OldProductlist,
                },
            };
        case ActionTypes.UPDATE_PRODUCT_LIST:
            const OldProductlistx = [...state.productList.list];
            const UpdateProductList = OldProductlistx.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        pi_no: action.payload.pi_no, 
                        name: action.payload.name,
                        brand: action.payload.brand,
                        model: action.payload.model,
                        quantity: action.payload.quantity,
                        supplier: action.payload.supplier,
                        item_code: action.payload.item_code, 
                        currency: action.payload.currency,
                        unit_cost_price: action.payload.unit_cost_price, 
                        incoterm: action.payload.incoterm, 
                        hs_code: action.payload.hs_code,
                        dimensions: action.payload.dimensions, 
                        floor: action.payload.floor, 
                        ps: action.payload.ps,
                        date: action.payload.date,
                        client_code: action.payload.client_code, 
                        pc_code: action.payload.pc_code,
                        description_french: action.payload.description_french,
                        description_english: action.payload.description_english, 
                        department: action.payload.department, 
                        unit: action.payload.unit,
                        ucp_usd: action.payload.ucp_usd,
                        tcp_usd: action.payload.tcp_usd,
                        usp_usd: action.payload.usp_usd,
                        tsp_usd: action.payload.tsp_usd,
                        lot_number: action.payload.lot_number,
                        brochure: action.payload.brochure,
                        remarks: action.payload.remarks,
                        country_of_origin: action.payload.country_of_origin, 
                        catalog: action.payload.catalog, 
                        cs: action.payload.cs,
                        ce: action.payload.ce,
                        iso: action.payload.iso,
                        maf: action.payload.maf,
                        warranty: action.payload.warranty,
                        lead_time: action.payload.lead_time,
                        category: action.payload.category, 
                        fator: action.payload.fator,
                        is_active: action.payload.is_active,
                        view_thumbnail_url: action.payload.view_thumbnail_url,
                        view_file_url: action.payload.view_file_url,
                        meta_field: action.payload.meta_field
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                productList:{
                    list: UpdateProductList,
                },
            };
        case ActionTypes.DELETE_PRODUCT:
            const OldProductListz = [...state.productList.list];
            const NewUpdatedProductList = [];
            OldProductListz && OldProductListz?.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedProductList.push(elm);
                }
            }); 
            return {
                ...state,
                productList: {
                    list: NewUpdatedProductList,
                },
            };
        case ActionTypes.VIEW_PRODUCT_DETAILS:
            return {
                ...state,
                viewProduct: action.payload,
            };
        case ActionTypes.GET_CATEGORY_LIST:
            return {
                ...state,
                categoryList: {
                    list: action.payload?.data?.data === undefined ? [] : action.payload?.data?.data,
                    pagination: action.payload.data?.pagination === undefined ? [] : action.payload.data?.pagination
                },
            };
        case ActionTypes.GET_ALL_CATEGORY_LIST:
            return {
                ...state,
                categoryAllList: (Object.keys(action.payload).length === 0 || action.payload.data.data === undefined) ? [] : action.payload.data.data,
            };
        case ActionTypes.ADD_CATEGORY_LIST:
            const OldCategorylist = [...state.categoryList.list];
            OldCategorylist.unshift(action.payload);
            return {
                ...state,
                categoryList:{
                    list: OldCategorylist,
                },
            };
        case ActionTypes.UPDATE_CATEGORY_LIST:
            const OldCategorylistx = [...state.categoryList.list];
            const UpdateCategoryList = OldCategorylistx.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        name: action.payload.name,
                        is_active: action.payload.is_active
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                categoryList:{
                    list: UpdateCategoryList,
                },
            };
        case ActionTypes.DELETE_CATEGORY_LIST:
            const OldCategoryListz = [...state.productList.list];
            const NewUpdatedCategoryList = [];
            OldCategoryListz && OldCategoryListz?.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedCategoryList.push(elm);
                }
            }); 
            return {
                ...state,
                categoryList:{
                    list: NewUpdatedCategoryList,
                },
            };
        case ActionTypes.GET_SETTINGS_LIST:
            const ObjectSettingsListId = {}
            action.payload?.data?.data.map((elm) => {
                ObjectSettingsListId[elm?.slug] = elm
            });

            return {
                ...state,
                settinglists: {
                    list: action.payload?.data?.data === undefined ? [] : action.payload?.data?.data,
                    pagination: action.payload.data?.pagination === undefined ? [] : action.payload.data?.pagination
                },
                settingsObjectIdWise: ObjectSettingsListId
            };
        case ActionTypes.ADD_SETTINGS_LIST:
            const OldSettinglist = [...state.settinglists.list];
            OldSettinglist.unshift(action.payload);
            return {
                ...state,
                settinglists:{
                    list: OldSettinglist,
                },
            };
        case ActionTypes.UPDATE_CATEGORY_LIST:
            const OldSettinglistx = [...state.settinglists.list];
            const UpdateSettingList = OldSettinglistx.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        name: action.payload.name
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                settinglists:{
                    list: UpdateSettingList,
                },
            };
        case ActionTypes.DELETE_SETTINGS_LIST:
            const OldsettinglistsListz = [...state.settinglists.list];
            const NewUpdatedsettinglistsList = [];
            OldsettinglistsListz && OldsettinglistsListz?.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedsettinglistsList.push(elm);
                }
            }); 
            return {
                ...state,
                settinglists:{
                    list: NewUpdatedsettinglistsList,
                },
            };
        case ActionTypes.ADD_TO_CART:
            const OldAddToCartlist = [...state.addToCartLists];
            OldAddToCartlist.unshift(action.payload);
            return {
                ...state,
                addToCartLists: OldAddToCartlist,
            };
        case ActionTypes.ADD_CART_MULTIPLE_QUANTITY:
            const OldAddToCartlists = [...state.addToCartLists];
            const UpdateProdCratlist = OldAddToCartlists.map((elm) => {
                if(elm?.id === action?.payload?.product.id) {
                    const AmountAdd = parseFloat(action?.payload?.product.data.unit_cost_price) + parseFloat(action?.payload?.mainCost)
                    return{
                        ...elm,
                        data : {
                            ...elm.data,
                            quantity: parseInt(action?.payload?.product.data.quantity) + parseInt(action?.payload?.mainQuantity),
                            unit_cost_price: AmountAdd.toFixed(1)
                        }
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                addToCartLists: UpdateProdCratlist,
            };
        case ActionTypes.REMOVE_CART_MULTIPLE_QUANTITY:
            const OldAddToCartlistsZ = [...state.addToCartLists];
            const UpdateProdCratlistS = OldAddToCartlistsZ.map((elm) => {
                if(elm?.id === action?.payload?.product.id) {
                    const AmountRemove = parseFloat(action?.payload?.product.data.unit_cost_price) - parseFloat(action?.payload?.mainCost)
                    return{
                        ...elm,
                        data : {
                            ...elm.data,
                            quantity: parseInt(action?.payload?.product.data.quantity) - parseInt(action?.payload?.mainQuantity),
                            unit_cost_price: AmountRemove.toFixed(1)
                        }
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                addToCartLists: UpdateProdCratlistS,
            };
        case ActionTypes.REMOVE_ADD_TO_CART:
            const OldCartListz = [...state.addToCartLists];
            const NewUpdatedCartList = [];
            OldCartListz && OldCartListz?.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedCartList.push(elm);
                }
            }); 
            return {
                ...state,
                addToCartLists: NewUpdatedCartList,
            };
        case ActionTypes.ADMIN_LOGOUT:
            return {
                ...initialData,
            };

        case ActionTypes.SET_STORE_PACKAGE_LIST:
            // console.log("action", action?.payload);
            return{
                ...state,
                packageList:action?.payload
            }
        
        case ActionTypes.SET_STORE_JET_LIST:
            // console.log("JET", action?.payload);
            return{
                ...state,
                jetList:action?.payload
            }
        case ActionTypes.GET_BOAT_OWNER_LIST:
            // console.log("action", action?.payload);
            return{
                ...state,
                boatOwnerList:{
                    data:action?.payload?.data,
                    pagination: action?.payload?.pagination
                }
            }
        case ActionTypes.SET_STORE_BOAT_TYPE_LIST:
            // console.log("boatTypeList", action?.payload);
            return{
                ...state,
                boatTypeList:action?.payload || []
            }
        case ActionTypes.SET_STORE_CURRENT_PACKAGE:
            if(action?.payload == null){
                return {
                    ...state,
                    currentPackage:[]
                }
            }else{
                if(Array.isArray(action?.payload)){
                    return{
                        ...state,
                        currentPackage:action?.payload,
                    }

                }else{
                    const currentPackageData = state?.currentPackage || [];
                    currentPackageData?.push(action?.payload);
                    return {
                        ...state,
                        currentPackage:currentPackageData, 
                      };    
                }
                
            }
           
        case ActionTypes.SET_STORE_DELETE_CURRENT_PACKAGE:
            const filteredCurrentPackage = state?.currentPackage?.filter(currentPack => currentPack != action?.payload);
            // console.log("filteredCurrentPackage", filteredCurrentPackage)
            return{
                ...state,
                currentPackage:filteredCurrentPackage,
            }
        case ActionTypes.SET_STORE_CURRENT_JET:
            if(action?.payload == null){
                return{
                    ...state,
                    currentJet:[],
                }
            }else{
                if(Array.isArray(action?.payload)){
                    return{
                        ...state,
                        currentJet:action?.payload,
                    }
                }else{
                    const currentJetData = state?.currentJet || [];
                    currentJetData.push(action?.payload)
                    return {
                        ...state,
                        currentJet:currentJetData,
                      };
                }
            }
           
        case ActionTypes.SET_STORE_DELETE_CURRENT_JET:
            const filteredCurrentJet = state?.currentJet?.filter(jet => jet != action?.payload)
            // console.log("filteredCurrentJet", filteredCurrentJet)
              return{
                ...state,
                currentJet:filteredCurrentJet,
              }


        case ActionTypes.SET_WEBSOCKET_REQ_RES:
        return {
            ...state,
            websocketReqRes: {
                request: action?.payload?.request || null,
                msg: action?.payload?.msg || "",
                status: action?.payload?.status || "",
                url: action?.payload?.url || "",
                response: action?.payload?.response || null
            }
        }

        case ActionTypes.SET_STORE_CURRENT_IMAGE:{
            if(action?.payload == null){
                return{
                    ...state,
                    currentImage:[],
                }
            }
            else{
                if(Array.isArray(action?.payload)){
                    let filteredImages = []
                    if(action?.payload?.length>0){
                        filteredImages = action?.payload?.map((img)=>{
                            return{
                                ...img?.image, 
                            }
                        })
                    }
                    return{
                        ...state,
                        currentImage:filteredImages,
                    }
                    
                }else{
                    const currentImageData = state?.currentImage || [];
                    currentImageData.push(action?.payload);
                    return{
                        ...state,
                        currentImage:currentImageData,
                    }
                }
            }
        }

        case ActionTypes.SET_STORE_DELETE_CURRENT_IMAGE:{
            const filteredImage = state?.currentImage?.filter(image => image.id != action?.payload);
            // console.log("filteredImage", filteredImage);
            return{
                ...state,
                currentImage:filteredImage,
            }
        }
            


        case ActionTypes.SET_WEBSOCKET_REQ_RES_REMOVE_DATA:
            return {
                ...state,
                websocketReqRes: {
                    request: null,
                    msg: "",
                    status: "",
                    url: "",
                }
            };

        case ActionTypes.GET_BROKER_LIST:
            console.log("action", action?.payload);
            return{
                ...state,
                brokerList:{
                    data:action?.payload?.data,
                    pagination: action?.payload?.pagination
                }
            }
        case ActionTypes.GET_CUSTOMER_LIST:
            // console.log("action", action?.payload);
            return{
                ...state,
                customerList:{
                    data:action?.payload?.data,
                    pagination: action?.payload?.pagination
                }
            }

        case ActionTypes.SET_STORE_BOAT_LIST: {
            const boatListData = action?.payload?.data
                ?.filter(boat => boat?.id) // Filter out boats without an id
                ?.map((boat) => {
                    const payloadData = {}
                    if(boat?.image?.length>0){
                        const parsedImages = boat?.image?.map((image) => {
                            const img = jsonParse(image.image);
                            return {
                                ...image,
                                image: image?.image ==  null || !image?.image || image?.image === "None"? null: JSON.parse(img)
                            };
                        });
                        payloadData.image = parsedImages;
                        payloadData.image_source = parsedImages[0]
                    }
                    return {
                        ...boat,
                        ...payloadData
                    };
                });
        
            return {
                ...state,
                boatList: {
                    data: boatListData,
                    pagination: action?.payload?.pagination
                }
            };
        }
        case ActionTypes.SET_STORE_UPDATE_BOAT:{
            const UpdatingBoat = state?.boatList?.data?.map((boat) => {
                if(boat.id === action?.payload?.boat_id){
                    return{
                        ...boat, 
                        is_active: action?.payload?.status === true ?"True":"False",
                        is_pos: action?.payload?.is_pos === true ?"True":"False",
                    }
                }else{
                    return boat
                }
            })            
            return{
                ...state,
                boatList:{
                    ...state?.boatList,
                    data:UpdatingBoat,
                }
            }
        }
        
        case ActionTypes.SET_STORE_MODAL_CONFIRM:{
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }

        case ActionTypes.GET_FAQ_LIST:
            // console.log("action", action?.payload);
            return{
                ...state,
                faqList:{
                    data:action?.payload?.data,
                    pagination: action?.payload?.pagination
                }
            } 
        case ActionTypes.GET_CONTACT_LIST:
            // console.log("action", action?.payload);
            return{
                ...state,
                contactList:{
                    data:action?.payload?.data,
                    pagination: action?.payload?.pagination
                }
            }    
        case ActionTypes.GET_TERMS_LIST:
            // console.log("action", action?.payload);
            return{
                ...state,
                termsList:{
                    data:action?.payload,
                }
            }    
        case ActionTypes.GET_POLICY_LIST:
            return{
                ...state,
                policyList:{
                    data:action?.payload,
                }
            }   
        case ActionTypes.SET_STORE_BOAT_DETAILS:{
            let payloadData = {};
            if(action?.payload?.image?.length > 0){
                const  parsedImages = action?.payload?.image?.map((image)=>{
                    const img = jsonParse(image.image);
                    return{
                        ...image,
                        image: image?.image ==null || image.image === "None" || !image.image ? null: JSON.parse(img),
                    }
                })
                payloadData.image = parsedImages;
            }
            return{
                ...state,
                boatDetails:{
                    ...action?.payload,
                    ...payloadData,
                },
            }
        }  
        case ActionTypes.SET_STORE_ORDER_LIST_BY_BOAT_AND_TIME:
            return {
              ...state,
              orderListParticular: action?.payload,
            };  
   
        case ActionTypes.GET_ORDER_LIST:
            return{
                ...state,
                orderList:{
                    data:action?.payload?.data,
                    pagination: action?.payload?.pagination
                }
            }
            case ActionTypes.GET_SINGLE_ORDER_LIST:
                return{
                    ...state,
                    singleOrder:{
                        data:action?.payload,
                    }
                } 
            case ActionTypes.GET_OWNER_PAYMENT_LIST:
                return{
                    ...state,
                    ownerPaymentList:{
                        data:action?.payload?.data,
                        pagination: action?.payload?.pagination
                    }
                }     
            case ActionTypes.GET_BROKER_PAYMENT_LIST:
                return{
                    ...state,
                    brokerPaymentList:{
                        data:action?.payload?.data,
                        pagination: action?.payload?.pagination
                    }
                } 
            case ActionTypes.GET_RECENT_ORDERS_LIST:
                return{
                    ...state,
                    recentOrderList:{
                        data:action?.payload?.data,
                        pagination: action?.payload?.pagination
                    }
                } 
            case ActionTypes.GET_TESTIMONIAL_LIST:
                return{
                    ...state,
                    testimonialList:{
                        data:action?.payload,
                    }
                }
            case ActionTypes.GET_ADMIN_DASHBOARD:
                return {
                    ...state,
                    adminDashboard: action.payload,
                }
            case ActionTypes.GET_OWNER_DASHBOARD:
                return {
                    ...state,
                    ownerDashboard: action.payload,
                }
            case ActionTypes.GET_BROKER_DASHBOARD:
                return {
                    ...state,
                    brokerDashboard: action.payload,
                }
        default:
        return state;
    }
};
export const ActionTypes = {
  IS_LOGIN: "IS_LOGIN",
  GET_USER_ID: "GET_USER_ID",
  GET_DEVICE_ID: "GET_DEVICE_ID",
  SELF_DETAILS: "SELF_DETAILS",
  EDIT_SELF_DETAILS: "EDIT_SELF_DETAILS",
  GET_ACCESSTOKEN: "GET_ACCESSTOKEN",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  GET_PERMISSION_LIST: "GET_PERMISSION_LIST",
  GET_USER_LIST: "GET_USER_LIST",
  GET_USER_LIST_ID_WISE: "GET_USER_LIST_ID_WISE",
  ADD_USER_LIST: "ADD_USER_LIST",
  EDIT_USER_LIST: "EDIT_USER_LIST",
  DELETE_USER: "DELETE_USER",
  GET_BRAND_LIST: "GET_BRAND_LIST",
  ADD_NEW_BRAND: "ADD_NEW_BRAND",
  EDIT_BRAND: "EDIT_BRAND",
  DELETE_BRAND: "DELETE_BRAND",
  GET_MODEL_LIST: "GET_MODEL_LIST",
  ADD_NEW_MODEL: "ADD_NEW_MODEL",
  EDIT_MODEL: "EDIT_MODEL",
  DELETE_MODEL: "DELETE_MODEL",
  GET_BRAND_MODELS_LIST: "GET_BRAND_MODELS_LIST",
  GET_SUPPLIER_LIST: "GET_SUPPLIER_LIST",
  GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
  ADD_SUPPLIER_LIST: "ADD_SUPPLIER_LIST",
  UPDATE_SUPPLIER_LIST: "UPDATE_SUPPLIER_LIST",
  GET_ALL_BRANDS_LIST: "GET_ALL_BRANDS_LIST",
  GET_ALL_MODEL_LIST: "GET_ALL_MODEL_LIST",
  SELECT_BRAND_GET_MODEL_LIST: "SELECT_BRAND_GET_MODEL_LIST",
  ADD_PRODUCT_LIST: "ADD_PRODUCT_LIST",
  UPDATE_PRODUCT_LIST: "UPDATE_PRODUCT_LIST",
  VIEW_PRODUCT_DETAILS: "VIEW_PRODUCT_DETAILS",
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_ADD_TO_CART: "REMOVE_ADD_TO_CART",
  GET_SUPPLIER_DETAILS: "GET_SUPPLIER_DETAILS",
  DELETE_SUPPLIER: "DELETE_SUPPLIER",
  LOADER_MAIN_START: "LOADER_MAIN_START",
  GET_ALL_SUPPLIERS_LIST: "GET_ALL_SUPPLIERS_LIST",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  GET_ALL_PRODUCT_LIST: "GET_ALL_PRODUCT_LIST",
  GET_DASHBOARD_DETAILS: "GET_DASHBOARD_DETAILS",
  ADD_CART_MULTIPLE_QUANTITY: "ADD_CART_MULTIPLE_QUANTITY",
  REMOVE_CART_MULTIPLE_QUANTITY: "REMOVE_CART_MULTIPLE_QUANTITY",
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  ADD_CATEGORY_LIST: "ADD_CATEGORY_LIST",
  UPDATE_CATEGORY_LIST: "UPDATE_CATEGORY_LIST",
  DELETE_CATEGORY_LIST: "DELETE_CATEGORY_LIST",
  GET_ALL_CATEGORY_LIST: "GET_ALL_CATEGORY_LIST",
  CLEAR_FORM_SET_FUCT: "CLEAR_FORM_SET_FUCT",
  GET_SETTINGS_LIST: "GET_SETTINGS_LIST",
  ADD_SETTINGS_LIST: "ADD_SETTINGS_LIST",
  DELETE_SETTINGS_LIST: "DELETE_SETTINGS_LIST",
  UPDATE_SETTINGS_LIST: "UPDATE_SETTINGS_LIST",
  SELECT_CURRENT_ADDED_BRAND: "SELECT_CURRENT_ADDED_BRAND",
  SELECT_CURRENT_ADDED_BRAND_MODEL: "SELECT_CURRENT_ADDED_BRAND_MODEL",
  GET_SELECTED_CURRENT_BRAND_IDS: "GET_SELECTED_CURRENT_BRAND_IDS",
  SELECT_CURRENT_ADDED_CATEGORY: "SELECT_CURRENT_ADDED_CATEGORY",
  SELECT_CURRENT_ADDED_SUPPLIER: "SELECT_CURRENT_ADDED_SUPPLIER",
  SET_STORE_PACKAGE_LIST: "SET_STORE_PACKAGE_LIST",
  SET_STORE_CURRENT_PACKAGE: "SET_STORE_CURRENT_PACKAGE",
  SET_STORE_CURRENT_JET: "SET_STORE_CURRENT_JET",
  SET_STORE_CURRENT_IMAGE: "SET_STORE_CURRENT_IMAGE",
  SET_STORE_DELETE_CURRENT_PACKAGE: "SET_STORE_DELETE_CURRENT_PACKAGE",
  SET_STORE_DELETE_CURRENT_JET: "SET_STORE_DELETE_CURRENT_JET",
  SET_STORE_DELETE_CURRENT_IMAGE: "SET_STORE_DELETE_CURRENT_IMAGE",
  SET_STORE_JET_LIST: "SET_STORE_JET_LIST",
  GET_BOAT_OWNER_LIST: "GET_BOAT_OWNER_LIST",
  SET_STORE_BOAT_TYPE_LIST: "SET_STORE_BOAT_TYPE_LIST",
  SET_WEBSOCKET_REQ_RES: "SET_WEBSOCKET_REQ_RES",
  SET_WEBSOCKET_REQ_RES_REMOVE_DATA: "SET_WEBSOCKET_REQ_RES_REMOVE_DATA",
  GET_BROKER_LIST: "GET_BROKER_LIST",
  GET_CUSTOMER_LIST: "GET_CUSTOMER_LIST",
  SET_STORE_BOAT_LIST: "SET_STORE_BOAT_LIST",
  SET_STORE_MODAL_CONFIRM: "SET_STORE_MODAL_CONFIRM",
  GET_FAQ_LIST: "GET_FAQ_LIST",
  GET_CONTACT_LIST: "GET_CONTACT_LIST",
  SET_STORE_UPDATE_BOAT: "SET_STORE_UPDATE_BOAT",
  GET_TERMS_LIST: "GET_TERMS_LIST",
  GET_POLICY_LIST: "GET_POLICY_LIST",
  SET_STORE_BOAT_DETAILS: "SET_STORE_BOAT_DETAILS",
  GET_ORDER_LIST: "GET_ORDER_LIST",
  GET_SINGLE_ORDER_LIST: "GET_SINGLE_ORDER_LIST",
  GET_OWNER_PAYMENT_LIST: "GET_OWNER_PAYMENT_LIST",
  GET_BROKER_PAYMENT_LIST: "GET_BROKER_PAYMENT_LIST",
  GET_RECENT_ORDERS_LIST: "GET_RECENT_ORDERS_LIST",
  GET_TESTIMONIAL_LIST: "GET_TESTIMONIAL_LIST",
  GET_ADMIN_DASHBOARD: "GET_ADMIN_DASHBOARD",
  GET_OWNER_DASHBOARD: "GET_OWNER_DASHBOARD",
  GET_BROKER_DASHBOARD: "GET_BROKER_DASHBOARD",
  SET_STORE_FIREBASE_DATA: "SET_STORE_FIREBASE_DATA",
  SET_STORE_ORDER_LIST_BY_BOAT_AND_TIME:"SET_STORE_ORDER_LIST_BY_BOAT_AND_TIME",
};
export const setStoreOrderListByBoatAndDate = (token) => {
  return {
    type: ActionTypes.SET_STORE_ORDER_LIST_BY_BOAT_AND_TIME,
    payload: token,
  };
};
export const setStoreFirebaseData = (payload) => {
  return {
    type: ActionTypes?.SET_STORE_FIREBASE_DATA,
    payload: payload,
  };
};
export const checkLogin = (status) => {
  return {
    type: ActionTypes.IS_LOGIN,
    payload: status,
  };
};

export const getUserId = (id) => {
  return {
    type: ActionTypes.GET_USER_ID,
    payload: id,
  };
};

export const getDeviceId = (id) => {
  return {
    type: ActionTypes.GET_DEVICE_ID,
    payload: id,
  };
};

export const DeleteUser = (user) => {
  return {
    type: ActionTypes.DELETE_USER,
    payload: user,
  };
};

export const ClearFormSetFutios = (date_) => {
  return {
    type: ActionTypes.CLEAR_FORM_SET_FUCT,
    payload: date_,
  };
};

export const getDashBoardDetails = (dashboard_details) => {
  return {
    type: ActionTypes.GET_DASHBOARD_DETAILS,
    payload: dashboard_details,
  };
};

export const ViewProjectDetails = (project) => {
  return {
    type: ActionTypes.VIEW_PRODUCT_DETAILS,
    payload: project,
  };
};

export const LoaderStartMain = (loader) => {
  return {
    type: ActionTypes.LOADER_MAIN_START,
    payload: loader,
  };
};

export const getSelfDetails = (user) => {
  return {
    type: ActionTypes.SELF_DETAILS,
    payload: user,
  };
};

export const getUserList = (list) => {
  return {
    type: ActionTypes.GET_USER_LIST,
    payload: list,
  };
};

export const getUserListIdWise = (list) => {
  return {
    type: ActionTypes.GET_USER_LIST_ID_WISE,
    payload: list,
  };
};

export const getAllBrandList = (list) => {
  return {
    type: ActionTypes.GET_ALL_BRANDS_LIST,
    payload: list,
  };
};

export const getCategoryList = (list) => {
  return {
    type: ActionTypes.GET_CATEGORY_LIST,
    payload: list,
  };
};

export const getAllCategoryList = (list) => {
  return {
    type: ActionTypes.GET_ALL_CATEGORY_LIST,
    payload: list,
  };
};

export const addCategoryList = (list) => {
  return {
    type: ActionTypes.ADD_CATEGORY_LIST,
    payload: list,
  };
};

export const updateCategoryList = (list) => {
  return {
    type: ActionTypes.UPDATE_CATEGORY_LIST,
    payload: list,
  };
};

export const deleteCategoryList = (list) => {
  return {
    type: ActionTypes.DELETE_CATEGORY_LIST,
    payload: list,
  };
};

export const getAllModelList = (list) => {
  return {
    type: ActionTypes.GET_ALL_MODEL_LIST,
    payload: list,
  };
};

export const getBrandList = (list) => {
  return {
    type: ActionTypes.GET_BRAND_LIST,
    payload: list,
  };
};

export const addNewBrand = (brandlist) => {
  return {
    type: ActionTypes.ADD_NEW_BRAND,
    payload: brandlist,
  };
};

export const editBrand = (brandlist) => {
  return {
    type: ActionTypes.EDIT_BRAND,
    payload: brandlist,
  };
};

export const editModel = (modellist) => {
  return {
    type: ActionTypes.EDIT_MODEL,
    payload: modellist,
  };
};

export const getBrandsWiseModelList = (list) => {
  return {
    type: ActionTypes.SELECT_BRAND_GET_MODEL_LIST,
    payload: list,
  };
};

export const deleteBrand = (brands) => {
  return {
    type: ActionTypes.DELETE_BRAND,
    payload: brands,
  };
};

export const deleteModel = (model) => {
  return {
    type: ActionTypes.DELETE_MODEL,
    payload: model,
  };
};

export const getModelList = (list) => {
  return {
    type: ActionTypes.GET_MODEL_LIST,
    payload: list,
  };
};

export const addNewModel = (brandlist) => {
  return {
    type: ActionTypes.ADD_NEW_MODEL,
    payload: brandlist,
  };
};

export const GetBrandModels = (brandsmodel) => {
  return {
    type: ActionTypes.GET_BRAND_MODELS_LIST,
    payload: brandsmodel,
  };
};

export const addUserList = (list) => {
  return {
    type: ActionTypes.ADD_USER_LIST,
    payload: list,
  };
};

export const editUserList = (list) => {
  return {
    type: ActionTypes.EDIT_USER_LIST,
    payload: list,
  };
};

export const getPermissionList = (permission) => {
  return {
    type: ActionTypes.GET_PERMISSION_LIST,
    payload: permission,
  };
};

export const getAccessToken = (token) => {
  return {
    type: ActionTypes.GET_ACCESSTOKEN,
    payload: token,
  };
};

export const getSupplierList = (list) => {
  return {
    type: ActionTypes.GET_SUPPLIER_LIST,
    payload: list,
  };
};

export const getSupplierAllList = (list) => {
  return {
    type: ActionTypes.GET_ALL_SUPPLIERS_LIST,
    payload: list,
  };
};

export const addSupplierList = (supplier) => {
  return {
    type: ActionTypes.ADD_SUPPLIER_LIST,
    payload: supplier,
  };
};

export const UpdateSupplierList = (supplier) => {
  return {
    type: ActionTypes.UPDATE_SUPPLIER_LIST,
    payload: supplier,
  };
};

export const getSupplierDetails = (details) => {
  return {
    type: ActionTypes.GET_SUPPLIER_DETAILS,
    payload: details,
  };
};

export const editSelfDetails = (user) => {
  return {
    type: ActionTypes.EDIT_SELF_DETAILS,
    payload: user,
  };
};

export const getProductList = (product) => {
  return {
    type: ActionTypes.GET_PRODUCT_LIST,
    payload: product,
  };
};

export const getAllProductList = (product) => {
  return {
    type: ActionTypes.GET_ALL_PRODUCT_LIST,
    payload: product,
  };
};

export const addProductList = (product) => {
  return {
    type: ActionTypes.ADD_PRODUCT_LIST,
    payload: product,
  };
};

export const UpdateProductList = (product_update) => {
  return {
    type: ActionTypes.UPDATE_PRODUCT_LIST,
    payload: product_update,
  };
};

export const addToCartList = (product) => {
  return {
    type: ActionTypes.ADD_TO_CART,
    payload: {
      id: new Date().getTime().toString(),
      data: product?.productz,
      mainQuantity: product?.mainQuantity,
      mainCost: product?.mainCost,
    },
  };
};

export const addMultipleQauntityCart = (product) => {
  return {
    type: ActionTypes.ADD_CART_MULTIPLE_QUANTITY,
    payload: product,
  };
};

export const removeMultipleQauntityCart = (product) => {
  return {
    type: ActionTypes.REMOVE_CART_MULTIPLE_QUANTITY,
    payload: product,
  };
};

export const deleteSupplier = (supplier) => {
  return {
    type: ActionTypes.DELETE_SUPPLIER,
    payload: supplier,
  };
};

export const deleteProduct = (product) => {
  return {
    type: ActionTypes.DELETE_PRODUCT,
    payload: product,
  };
};

export const RemoveCartList = (cart) => {
  return {
    type: ActionTypes.REMOVE_ADD_TO_CART,
    payload: cart,
  };
};

export const getSettingList = (setting) => {
  return {
    type: ActionTypes.GET_SETTINGS_LIST,
    payload: setting,
  };
};

export const addSettingList = (setting) => {
  return {
    type: ActionTypes.ADD_SETTINGS_LIST,
    payload: setting,
  };
};

export const updateSettingList = (setting) => {
  return {
    type: ActionTypes.UPDATE_SETTINGS_LIST,
    payload: setting,
  };
};

export const deleteSettingList = (setting) => {
  return {
    type: ActionTypes.DELETE_SETTINGS_LIST,
    payload: setting,
  };
};

export const clearRedux = () => {
  return {
    type: ActionTypes.ADMIN_LOGOUT,
  };
};

export const selectCurrentAddedBrand = (brands) => {
  return {
    type: ActionTypes.SELECT_CURRENT_ADDED_BRAND,
    payload: brands,
  };
};

export const selectCurrentAddedBrandModel = (models) => {
  return {
    type: ActionTypes.SELECT_CURRENT_ADDED_BRAND_MODEL,
    payload: models,
  };
};

export const selectCurrentAddedCategory = (category) => {
  return {
    type: ActionTypes.SELECT_CURRENT_ADDED_CATEGORY,
    payload: category,
  };
};

export const selectCurrentAddedSupplier = (supplier) => {
  return {
    type: ActionTypes.SELECT_CURRENT_ADDED_SUPPLIER,
    payload: supplier,
  };
};

export const getSelectedCurrentBrandIDs = (brand) => {
  return {
    type: ActionTypes.GET_SELECTED_CURRENT_BRAND_IDS,
    payload: brand,
  };
};
export const setStorePackageList = (data) => {
  return {
    type: ActionTypes.SET_STORE_PACKAGE_LIST,
    payload: data,
  };
};
export const setStoreJetList = (data) => {
  return {
    type: ActionTypes.SET_STORE_JET_LIST,
    payload: data,
  };
};

export const getBoatOwnerList = (data) => {
  return {
    type: ActionTypes.GET_BOAT_OWNER_LIST,
    payload: data,
  };
};
export const setStoreBoatTypeList = (data) => {
  return {
    type: ActionTypes.SET_STORE_BOAT_TYPE_LIST,
    payload: data,
  };
};
export const setStoreCurrentPackage = (data) => {
  return {
    type: ActionTypes.SET_STORE_CURRENT_PACKAGE,
    payload: data,
  };
};
export const setStoreDeleteCurrentPackage = (data) => {
  return {
    type: ActionTypes.SET_STORE_DELETE_CURRENT_PACKAGE,
    payload: data,
  };
};
export const setStoreCurrentJet = (data) => {
  return {
    type: ActionTypes.SET_STORE_CURRENT_JET,
    payload: data,
  };
};
export const setStoreDeleteCurrentJet = (data) => {
  return {
    type: ActionTypes.SET_STORE_DELETE_CURRENT_JET,
    payload: data,
  };
};
export const setStoreCurrentImage = (data) => {
  return {
    type: ActionTypes.SET_STORE_CURRENT_IMAGE,
    payload: data,
  };
};
export const setStoreDeleteCurrentImage = (data) => {
  return {
    type: ActionTypes.SET_STORE_DELETE_CURRENT_IMAGE,
    payload: data,
  };
};
export const setWebsocketReqRes = (token) => {
  return {
    type: ActionTypes.SET_WEBSOCKET_REQ_RES,
    payload: token,
  };
};
export const setWebsocketReqResRemoveData = (token) => {
  return {
    type: ActionTypes.SET_WEBSOCKET_REQ_RES_REMOVE_DATA,
    payload: token,
  };
};
export const getBrokerList = (data) => {
  return {
    type: ActionTypes.GET_BROKER_LIST,
    payload: data,
  };
};

export const getCustomerList = (data) => {
  return {
    type: ActionTypes.GET_CUSTOMER_LIST,
    payload: data,
  };
};
export const setStoreBoatList = (data) => {
  return {
    type: ActionTypes.SET_STORE_BOAT_LIST,
    payload: data,
  };
};
export const setConfirmModalPopup = (data) => {
  return {
    type: ActionTypes.SET_STORE_MODAL_CONFIRM,
    payload: data,
  };
};

export const getFaqList = (data) => {
  return {
    type: ActionTypes.GET_FAQ_LIST,
    payload: data,
  };
};
export const getcontactList = (data) => {
  return {
    type: ActionTypes.GET_CONTACT_LIST,
    payload: data,
  };
};
export const setStoreUpdateBoat = (data) => {
  return {
    type: ActionTypes.SET_STORE_UPDATE_BOAT,
    payload: data,
  };
};

export const getTermsList = (data) => {
  return {
    type: ActionTypes.GET_TERMS_LIST,
    payload: data,
  };
};
export const getPolicyList = (data) => {
  return {
    type: ActionTypes.GET_POLICY_LIST,
    payload: data,
  };
};
export const setStoreBoatDetails = (data) => {
  return {
    type: ActionTypes.SET_STORE_BOAT_DETAILS,
    payload: data,
  };
};
export const getOrderList = (data) => {
  return {
    type: ActionTypes.GET_ORDER_LIST,
    payload: data,
  };
};
export const getSingleOrderList = (data) => {
  return {
    type: ActionTypes.GET_SINGLE_ORDER_LIST,
    payload: data,
  };
};
export const getOwnerPaymentList = (data) => {
  return {
    type: ActionTypes.GET_OWNER_PAYMENT_LIST,
    payload: data,
  };
};
export const getBrokerPaymentList = (data) => {
  return {
    type: ActionTypes.GET_BROKER_PAYMENT_LIST,
    payload: data,
  };
};
export const getRecentOrdersList = (data) => {
  return {
    type: ActionTypes.GET_RECENT_ORDERS_LIST,
    payload: data,
  };
};
export const getTestimonialList = (data) => {
  return {
    type: ActionTypes.GET_TESTIMONIAL_LIST,
    payload: data,
  };
};
export const getAdminDashboard = (data) => {
  return {
    type: ActionTypes.GET_ADMIN_DASHBOARD,
    payload: data,
  };
};
export const getOwnerDashboard = (data) => {
  return {
    type: ActionTypes.GET_OWNER_DASHBOARD,
    payload: data,
  };
};
export const getBrokerDashboard = (data) => {
  return {
    type: ActionTypes.GET_BROKER_DASHBOARD,
    payload: data,
  };
};
